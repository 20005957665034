<template>
    <div class="item-desc">
        <el-select
        :disabled="true"
        v-model="province"
        placeholder="请选择省份"
        size="mini"
        style="margin-right: 10px"
        >
        <el-option
            v-for="item in provinceList"
            :key="item.id"
            :label="item.districtName"
            :value="item.id"
        >
        </el-option>
        </el-select>
        <el-select
        :disabled="true"
        v-model="city"
        placeholder="请选择城市"
        size="mini"
        style="margin-right: 10px"
        >
        <el-option
            v-for="item in cityList"
            :key="item.id"
            :label="item.districtName"
            :value="item.id"
        >
        </el-option>
        </el-select>
        <el-select
        v-if="filed=='orgId'"
        v-model="orgId"
        :clearable="true"
        placeholder="请选择区县"
        size="mini"
        @change="handleAreaChange"
        >
        <el-option
            v-for="item in areaList"
            :key="item.orgId"
            :label="item.districtName"
            :value="item.orgId"
        >
        </el-option>
        </el-select>
        <el-select
        v-if="filed=='id'"
        v-model="orgId"
        :clearable="true"
        placeholder="请选择区县"
        size="mini"
        @change="handleAreaChange"
        >
        <el-option
            v-for="item in areaList"
            :key="item.id"
            :label="item.districtName"
            :value="item.id"
        >
        </el-option>
        </el-select>
    </div>
</template>
<script>
import companyRequest from "@/api/company";
export default{
    props:{
        filed:{
            type:String,
            default:"orgId"
        }
    },
    created(){
        this.handleProvinceChange(17);
        this.handlecityChange(240);
    },
    data(){
        return{
            provinceList:[],
            cityList:[],
            areaList:[],
            province: "江西省", //选中省
            city: "广信区", //选中市
            area: "", //区域
            areaId: "", // 区域的id
            orgId:null,
        }
    },
    methods:{
        getCityList(location, id) {
        // console.log(location,id)
        companyRequest.getCityList({ id: id }).then((res) => {
            if (res.code === 200) {
            // console.log(res)
            this[location] = res.data;
            }
        });
        },
        async handleProvinceChange(val) {
        // console.log("选择的省",val)
        this.province = "江西省";
        await this.getCityList("cityList", val);
        // this.areaList = []
        // this.area = ''
        // this.searchAsFirst()
        },
        async handlecityChange(val) {
        // console.log("选择的市",val)
        this.city = "广信区";
        await this.getCityList("areaList", val);
        // this.area = ''
        // this.searchAsFirst()
        },
        handleAreaChange(val) {
            // console.log("选择的区域",val)
            // this.areaId = val;
            if(this.orgId==''){
                this.orgId=null
            }
            this.$emit("getList",this.orgId)
        },
    }
}
</script>


