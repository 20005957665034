<template>
    <div class="booking-history">
        <div class="title">
            <div class="sub-title">招聘会预约历史</div>
        </div>
        <el-card class="box-card" style="min-height: 100%">
             <Changecity @getList="handleAreaChange"></Changecity>
            <div v-if="state" style="margin-top:15px;">
                <div class="history-list" v-if="jobFairHistoryList.length>0">
                    <div class="history-list-item flex" v-for="(item, index) in jobFairHistoryList" :key="`jobFairHistoryList${index}`">
                        <div class="item-pic">
                            <img :src="item.url" />
                            <!-- <img src="../../../assets/img/company/tigger.jpg" /> -->
                        </div>
                        <div class="item-info flex">
                            <div class="info-name">{{item.jobName}}</div>
                            <!-- <div class="info-company">已有<span class="warnning">11</span>家企业参会，共有<span class="warnning">108</span>个职位！</div> -->
                            <div class="info-place">
                                <i class="el-icon-location iconstyle"></i>
                                <span>举办地点：{{item.holdField}}</span>
                                <i class="el-icon-info iconstyle" style="margin-left:30px"></i>
                                <span>联系人： {{item.contacts}}</span>
                                <i class="el-icon-mobile-phone iconstyle" style="margin-left:30px"></i>
                                <span>联系电话： {{item.contactsPhone}}</span>
                                <i class="el-icon-rank iconstyle" style="margin-left:30px"></i>
                                <span>展位区域：{{item.boothArea}}</span>
                            </div>
                            <div class="info-place info-time">
                                <i class="el-icon-message-solid iconstyle"></i>
                                <span>举办时间：{{item.holdStartTime}}  <span class="warnning">至</span>  {{item.holdEndTime}}</span>
                            </div>
                            <div class="info-place info-bus">
                                <i class="el-icon-s-help iconstyle"></i>
                                <span>参展职位：{{item.position}}</span>
                                <!-- <i class="el-icon-info iconstyle"></i>
                                <span>联系人： {{item.contacts}}</span> -->
                            </div>
                        </div>
                        <!-- <div class="item-order">
                            <el-button class="active" @click="goOrderBooth(item.id)">立即预约</el-button>
                        </div> -->
                        <div class="item-icon">
                            <!-- 未开始 -->
                            <!-- <img src="../../../assets/img/company/fair_1.png" alt="" v-if="item.status===5"> -->
                            <!-- 已开始 -->
                            <!-- <img src="../../../assets/img/company/fair_2.png" alt="" v-else-if="item.status===3"> -->
                            <!-- 进行中 -->
                            <!-- <img src="../../../assets/img/company/fair_3.png" alt="" v-else-if="item.status===1"> -->
                            <!-- 已结束 -->
                            <!-- <img src="../../../assets/img/company/fair_4.png" alt="" v-else-if="item.status===4"> -->
                            <!-- 取消 -->
                            <!-- <img src="../../../assets/img/company/fair_5.png" alt="" v-else> -->
                        </div>
                    </div>
                    <app-pagination @handleSizeChange="handleSize" @handleCurrentChange="handleCurrent" :total="total"></app-pagination>
                </div>
                <div class="no" v-else>
                    <img src="../../../assets/img/company/loading.png" alt="" srcset="" class="loading-img">
                    <p>正在加载中...</p>
                </div>
            </div>
            <div class="no-list flex" v-else>
                <img src="../../../assets/img/company/empty.png" alt="" srcset="">
                <p class="no-tips">亲爱的用户，目前还没有相关新的招聘会信息~~</p>
            </div>
        </el-card>
    </div>
</template>
<script>
import AppPagination from '../../../components/pagination/AppPagination'
import companyRequest from '../../../api/company'
import Changecity from '@/components/Changecity.vue'
export default {
    components: {
        AppPagination,
        Changecity
    },
    mounted() {
        this.getJobFairHistoryList()
    },
    data() {
        return {
            total: 1,
            state: true,
            jobFairHistoryList: [],
            search: {
                orgId:null,
                pageNo: 1,
                pageSize: 10
            }
        }
    },
    methods: {
        handleAreaChange(data){
            this.search.orgId=data
            this.search.pageNo=1
            this.getJobFairHistoryList()
        },
        handleSize(size) {
            this.search.pageSize = size
            this.search.pageNo = 1
            this.getJobFairHistoryList()
        },
        handleCurrent(num) {
            this.search.pageNo = num
            this.getJobFairHistoryList()
        },
        //获取企业参加招聘会历史
        getJobFairHistoryList() {
            companyRequest.getJobFairHistoryList(this.search).then((res) => {
                if (res.code === 200) {
                    this.jobFairHistoryList = res.data.list
                    if (this.jobFairHistoryList.length === 0) {
                        this.state = false
                    }else{
                        this.state=true
                    }
                    this.total = res.data.total
                }
            }).catch((err) => {
                this.state = false
            })
        }
    }
}
</script>
<style lang="scss" scoped>
@import '../../../assets/css/global.scss';
    .booking-history{
        height: 100%;
    }
    .history-list{
        .history-list-item{
            // width: 80%;
            transition: all .3s;
            position: relative;
            padding: 20px;
            // border: 1px dotted #eee;
            border: 1px solid #eee;
            margin-bottom: 15px;
            margin-right: 20px;
            &:hover{
                box-shadow: 1px 3px 3px #f3f3f3;
                // transform: translateY(-3px);
            }
            .item-pic{
                width: 180px;
                margin-right: 40px;
                img{
                    width: 180px;
                    height: 180px;
                }
            }
            .item-info{
                min-width: 70%;
                height: 180px;
                overflow: hidden;
                flex-direction: column;
                justify-content: space-between;
                .info-name{
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 40px;
                }
                .info-company{
                    color: #666;
                    font-size: 13px;
                    .warnning{
                        color: $main;
                        font-weight: 700;
                    }
                }
                .info-place{
                    margin-top: 45px;
                    font-size: 13px;
                    color: #666;
                    .warnning{
                        // font-weight: 700;
                        color: #333;
                        padding: 0 5px;
                    }
                }
                .info-time{
                    margin-top: 10px;
                }
                .info-bus{
                    margin-top: 10px;
                }
            }
            .item-order{
                margin-left: 150px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .el-button{
                    width: 150px;
                    letter-spacing: 2px;
                    &:hover{
                        background: $hover;
                    }
                }
            }
            .item-icon{
                position: absolute;
                top: 0;
                right: 0;
            }
        }
    }
    .no-list{
        margin: 150px 0 200px 0;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        p{

        }

    }
</style>